import React from 'react';
import j1 from "../images/jewelry/j1.jpg";
import j2 from "../images/jewelry/j2.jpg";
import j3 from "../images/jewelry/j3.jpg";
import j4 from "../images/jewelry/j4.jpg";

import FullScreenHorizontalScroll from "../components/FullScreenHorizontalScroll"


function Jewelry(props){

    const images = [j1, j2, j3, j4];

    return (
        <FullScreenHorizontalScroll images={images}/>
            );
}


export default Jewelry;





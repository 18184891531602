import React from 'react';
import {
    Link
} from "react-router-dom";

import chinaBig from "../images/photography/8-CHINA-ON-PHOTOGRAPHY/china1.jpg"
import closeUpsBig from "../images/photography/3-CLOSE-UPS/closeUps1.jpg"
import dadaBig from "../images/photography/1-DADA-Editorial/dada1.jpg"
import dailyBig from "../images/photography/13-Daily/daily1.jpg"
import fashionStoryBig from "../images/photography/2-Fashion-Story/fashionStory1.jpg"
import ghostBig from "../images/photography/6-Ghost/ghost2.jpg"
import landscapesBig from "../images/photography/7-Landscapes/landscapes3.jpg"
import greyScaleBig from "../images/photography/5-Grey-scale/greyScale1.jpg"
import meltingSilverBig from "../images/photography/4-Melting-Silver/meltingSilver1.jpg"
import moroccoBig from "../images/photography/10-Morocco/morocco2.jpg"
import nycBig from "../images/photography/14-NYC/nyc3.jpg"
import peopleBig from "../images/photography/11-PEOPLE/people1.jpg"
import reflectionsBig from "../images/photography/9-Reflections/reflections1.jpg"
import santanderBig from "../images/photography/12-Santander/santander1.jpg"
import deepStoriesBig from "../images/photography/15-DeepStories/deepStories1.jpg"

import china from "../images/photography/photography-small-screens/china-photo.jpg"
import closeUps from "../images/photography/photography-small-screens/closeUps-photo.jpg"
import dada from "../images/photography/photography-small-screens/dada-photo.jpg"
import daily from "../images/photography/photography-small-screens/daily-photo.jpg"
import fashionStory from "../images/photography/photography-small-screens/fashionStory-photo.jpg"
import ghost from "../images/photography/photography-small-screens/ghost-photo.jpg"
import landscapes from "../images/photography/photography-small-screens/landscapes-photo.jpg"
import greyScale from "../images/photography/photography-small-screens/greyScale-photo.jpg"
import meltingSilver from "../images/photography/photography-small-screens/meltingSilver-photo.jpg"
import morocco from "../images/photography/photography-small-screens/morocco-photo.jpg"
import nyc from "../images/photography/photography-small-screens/nyc-photo.jpg"
import people from "../images/photography/photography-small-screens/people-photo.jpg"
import reflections from "../images/photography/photography-small-screens/reflections-photo.jpg"
import santander from "../images/photography/photography-small-screens/santander-photo.jpg"
import deepStories from "../images/photography/photography-small-screens/deepStories-photo.jpg"
import paint from "../images/photography/photography-small-screens/paint.jpg"
import csdmmRunway from "../images/photography/photography-small-screens/csdmm.jpg"


function Photography(props) {

    /*  <div style={{height: "90vh"}}>
          <div className="row" style={{height: "100%", paddingTop: "2%", paddingBottom: "2%", paddingLeft: "5%"}}>
              <div className="col-md-2" style={{height: "100%", paddingTop: "3%"}}>
                  <ol className="list-group" style={{maxHeight: "100%"}}>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/dada-editorial"
                             style={{color: 'gray'}}>DADA
                              Editorial</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/nyc">NYC</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/fashion-story">Fashion Story</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/close-ups">Close Ups</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/melting-silver">Melting Silver</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/grey-scale">Grey Scale</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/ghost">Ghost</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/landscapes">Landscapes</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/china-on-photography">China on
                              Photography</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/reflections">Reflections</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/morocco">Morocco</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/people">People</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/santander">Santander</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/daily">Daily</Link>
                      </li>
                      <li className="align-items-center" style={{border: "none"}}>
                          <Link className="photo-links" to="/photography/deep-stories">Deep Stories</Link>
                      </li>
                  </ol>
              </div>

              <div className="col-md-10" style={{height: "100%"}}>
                  <div className="scroll-images-photography" style={{maxHeight: "100%"}}>
                      <div style={{Height: "100%"}}>
                          <img className="images-in-photography-scroll" src={dada1} alt=""/>
                      </div>
                      <div style={{Height: "100%"}}>
                          <img className="images-in-photography-scroll" src={dada2} alt=""/>
                      </div>
                      <div style={{Height: "100%"}}>
                          <img className="images-in-photography-scroll" src={dada3} alt=""/>
                      </div>
                      <div style={{Height: "100%"}}>
                          <img className="images-in-photography-scroll" src={dada4} alt=""/>
                      </div>
                      <div style={{Height: "100%"}}>
                          <img className="images-in-photography-scroll" src={dada5} alt=""/>
                      </div>
                      <div style={{Height: "100%"}}>
                          <img className="images-in-photography-scroll" src={dada6} alt=""/>
                      </div>

                  </div>
              </div>
          </div>
      </div> */

    return (
        <div>
            <div className="row" align="center" style={{padding: "2%"}}>

                <div className="col-sm-3">
                    <img className="card-img" src={dada} alt=""/>
                    <div className="content">
                        <Link to="/photography/dada-editorial" style={{color: "black"}}>
                            <div className="text">
                                DADA Editorial
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={nyc} alt=""/>
                    <div className="content">
                        <Link to="/photography/nyc" style={{color: "black"}}>
                            <div className="text">
                                NYC
                            </div>
                        </Link>
                    </div>
                </div>


                <div className="col-sm-3">
                    <img className="card-img" src={fashionStory} alt=""/>
                    <div className="content">
                        <Link to="/photography/fashion-story" style={{color: "black"}}>
                            <div className="text">
                                Fashion Story
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={closeUps} alt=""/>
                    <div className="content">
                        <Link to="/photography/close-ups" style={{color: "black"}}>
                            <div className="text">
                                Close Ups
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="row" align="center" style={{padding: "2%"}}>

                <div className="col-sm-3">
                    <img className="card-img" src={meltingSilver} alt=""/>
                    <div className="content">
                        <Link to="/photography/melting-silver" style={{color: "black"}}>
                            <div className="text">
                                Melting Silver
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={greyScale} alt=""/>
                    <div className="content">
                        <Link to="/photography/grey-scale" style={{color: "black"}}>
                            <div className="text">
                                Grey Scale
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={ghost} alt=""/>
                    <div className="content">
                        <Link to="/photography/ghost" style={{color: "black"}}>
                            <div className="text">
                                Ghost
                            </div>
                        </Link>
                    </div>

                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={landscapes} alt=""/>
                    <div className="content" style={{border: "2px solid white"}}>
                        <Link to="/photography/landscapes" style={{color: "black"}}>
                            <div className="text">
                                Landscapes
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
            <div className="row" align="center" style={{padding: "2%"}}>

                <div className="col-sm-3">
                    <img className="card-img" src={china} alt=""/>
                    <div className="content">
                        <Link to="/photography/china-on-photography" style={{color: "black"}}>
                            <div className="text">
                                China on Photography
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={reflections} alt=""/>
                    <div className="content">
                        <Link to="/photography/reflections" style={{color: "black"}}>
                            <div className="text">
                                Reflections
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={morocco} alt=""/>
                    <div className="content">
                        <Link to="/photography/morocco" style={{color: "black"}}>
                            <div className="text">
                                Morocco
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={people} alt=""/>
                    <div className="content">
                        <Link to="/photography/people" style={{color: "black"}}>
                            <div className="text">
                                People
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
            <div className="row" align="center" style={{padding: "2%"}}>

                <div className="col-sm-3">
                    <img className="card-img" src={csdmmRunway} alt=""/>
                    <div className="content" style={{border:"transparent"}}>
                        <Link to="/photography/csdmm-runway" style={{color: "black"}}>
                            <div className="text">
                                Csdmm Runway
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={daily} alt=""/>
                    <div className="content">
                        <Link to="/photography/daily" style={{color: "black"}}>
                            <div className="text">
                                Daily
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={deepStories} alt=""/>
                    <div className="content">
                        <Link to="/photography/deep-stories" style={{color: "black"}}>
                            <div className="text">
                                Deep Stories
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                    <img className="card-img" src={paint} alt=""/>
                    <div className="content">
                        <Link to="/photography/paint" style={{color: "black"}}>
                            <div className="text">
                                Paint
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="row" align="center" style={{padding: "2%"}}>

                <div className="col-sm-3">
                    <img className="card-img" src={santander} alt=""/>
                    <div className="content" style={{border:"transparent"}}>
                        <Link to="/photography/santander" style={{color: "black"}}>
                            <div className="text">
                                Santander
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-3">
                </div>

                <div className="col-sm-3">
                </div>

                <div className="col-sm-3">
                </div>
            </div>
        </div>
    )
}

export default Photography;


import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/5-Grey-scale/greyScale1.jpg";
import image2 from "../../images/photography/5-Grey-scale/greyScale2.jpg";
import image3 from "../../images/photography/5-Grey-scale/greyScale3.jpg";
import image4 from "../../images/photography/5-Grey-scale/greyScale4.jpg";
import image5 from "../../images/photography/5-Grey-scale/greyScale5.jpg";
import image6 from "../../images/photography/5-Grey-scale/greyScale6.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function GreyScale(props) {

        const images = [image1, image2, image3, image4, image5, image6];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )

}

export default GreyScale;


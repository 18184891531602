import React from 'react';

import image1 from "../../images/photography/17-CsdmmRunway/csdmmRunway1.jpg";
import image2 from "../../images/photography/17-CsdmmRunway/csdmmRunway2.jpg";
import image3 from "../../images/photography/17-CsdmmRunway/csdmmRunway3.jpg";
import image4 from "../../images/photography/17-CsdmmRunway/csdmmRunway4.jpg";
import image5 from "../../images/photography/17-CsdmmRunway/csdmmRunway5.jpg";
import image6 from "../../images/photography/17-CsdmmRunway/csdmmRunway6.jpg";
import image7 from "../../images/photography/17-CsdmmRunway/csdmmRunway7.jpg";
import image8 from "../../images/photography/17-CsdmmRunway/csdmmRunway8.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function CsdmmRunway(props) {

    const images = [image1, image2, image3, image4, image5, image6, image7, image8];
    return (
        <FullScreenHorizontalScroll images={images}/>
    )
}

export default CsdmmRunway;

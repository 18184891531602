import image1 from "../../images/fashion_projects/fp8.jpg";
import image2 from "../../images/fashion_projects/fp8i2.jpg";
import image3 from "../../images/fashion_projects/fp8i3.jpg";
import React from "react";
import FashionProjectDisplay3 from "../../components/FashionProjectDisplay3"


function FashionProject8(props) {

    return(
        <FashionProjectDisplay3 image1={image1} image2={image2} image3={image3}/>
    )

}

export default FashionProject8;

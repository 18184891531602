import React from 'react';
import aboutImage from '../images/about/about.jpg';
import email from '../images/email.png';


function About(props) {

    return (
        <div className="responsive-div" style={{height: "90vh", width:"100%"}}>
            <div className="row" style={{padding:"3%", paddingLeft:"2%"}}>
                <div className="col-lg-6" style={{padding:"2%", maxHeight: "100%", display: "flex", justifyContent: "center", flexDirection: "column"}}>
                    <p align="center" style={{fontSize:"0.9em", fontFamily: "Arial"}}>
                        <i>Nuria Revuelta Fashion Designer and Photographer</i>
                    </p>
                    <p align="center" style={{fontSize:"0.9em", fontFamily: "Arial", paddingRight: "15%", paddingLeft: "15%"}}>
                        Interested on the thinking process behind each collection, the craftsmanship, couture and
                        handmade details, the experimentation, textile mixture and print making.
                        Understanding garments as something
                        very detailed and delicate meant to be special and last. Thinking about fashion as a way to
                        express her artistic thought and ideas.
                    </p>
                    <p align="center" style={{fontFamily: "Arial", paddingTop: "2%"}}>
                        <a href="mailto:nuriarevueltasan@gmail.com" target="_blank">
                            <img className="" src={email} style={{width: "40px", height: "40px"}}/>
                        </a>
                        <a style={{textAlign:"center", paddingLeft:"1%"}}> nuriarevueltasan@gmail.com</a>
                    </p>
                </div>
                <div className="col-lg-6" style={{padding:"2%", maxHeight: "100%", display: "flex", justifyContent: "center", flexDirection: "column"}}>
                    <img className="" src={aboutImage} alt="" style={{maxWidth:"100%", maxHeight:"100%", alignSelf:"center"}}/>
                </div>
            </div>
        </div>
    );
}


export default About;

import React from "react";

class FullScreenHorizontalScroll extends React.Component {

    render() {
        const images = this.props.images;

        return (
                <div className="box" style={{height:"90vh"}}>
                        <img className="scroll-images" src={images[0]} alt="" />
                        <img className="scroll-images" src={images[1]} alt="" />
                        <img className="scroll-images" src={images[2]} alt="" />
                        <img className="scroll-images" src={images[3]} alt="" />
                        <img className="scroll-images" src={images[4]} alt="" />
                        <img className="scroll-images" src={images[5]} alt="" />
                        <img className="scroll-images" src={images[6]} alt="" />
                        <img className="scroll-images" src={images[7]} alt="" />
                        <img className="scroll-images" src={images[8]} alt="" />
                        <img className="scroll-images" src={images[9]} alt="" />
                    </div>
        );
    }

}

export default FullScreenHorizontalScroll;

import React from 'react';
import {
    Link
} from "react-router-dom";
import video from "../../images/photography/15-DeepStories/deepStories-video.mp4";


function DeepStories(props) {

        return (
            <div className="row align-items-center"  style={{height:"80vh", paddingLeft:"15%", paddingRight:"15%", textAlign:"center"}}>
                <video controls autoPlay muted playsInline style={{width:"100%", alignSelf:"center", border:"2px solid black"}}>
                    <source src={video} type="video/mp4"/>
                </video>
            </div>
        )

}

export default DeepStories;


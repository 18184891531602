import image1 from "../../images/fashion_projects/fp6.jpg";
import image2 from "../../images/fashion_projects/fp6i2.jpg";
import image3 from "../../images/fashion_projects/fp6i3.jpg";
import image4 from "../../images/fashion_projects/fp6i4.jpg";
import image5 from "../../images/fashion_projects/fp6i5.jpg";
import image6 from "../../images/fashion_projects/fp6i6.jpg";
import React from "react";
import FashionProjectDisplay6 from "../../components/FashionProjectDisplay6"


function FashionProject6(props) {

    return(
        <FashionProjectDisplay6 image1={image1} image2={image2} image3={image3} image4={image4} image5={image5} image6={image6}/>
    )

}

export default FashionProject6;

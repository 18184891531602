import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/2-Fashion-Story/fashionStory1.jpg";
import image2 from "../../images/photography/2-Fashion-Story/fashionStory2.jpg";
import image3 from "../../images/photography/2-Fashion-Story/fashionStory3.jpg";
import image4 from "../../images/photography/2-Fashion-Story/fashionStory4.jpg";
import image5 from "../../images/photography/2-Fashion-Story/fashionStory5.jpg";
import image6 from "../../images/photography/2-Fashion-Story/fashionStory6.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function FashionStory(props) {

        const images = [image1, image2, image3, image4, image5, image6];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )

}

export default FashionStory;


import React from 'react';

import image1 from "../../images/photography/16-Paint/paint1.jpg";
import image2 from "../../images/photography/16-Paint/paint2.jpg";
import image3 from "../../images/photography/16-Paint/paint3.jpg";
import image4 from "../../images/photography/16-Paint/paint4.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function Paint(props) {

    const images = [image1, image2, image3, image4];
    return (
        <FullScreenHorizontalScroll images={images}/>
    )
}

export default Paint;

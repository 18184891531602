import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/1-DADA-Editorial/dada1.jpg";
import image2 from "../../images/photography/1-DADA-Editorial/dada2.jpg";
import image3 from "../../images/photography/1-DADA-Editorial/dada3.jpg";
import image4 from "../../images/photography/1-DADA-Editorial/dada4.jpg";
import image5 from "../../images/photography/1-DADA-Editorial/dada5.jpg";
import image6 from "../../images/photography/1-DADA-Editorial/dada6.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function DadaEditorial(props) {

        const images = [image1, image2, image3, image4, image5, image6];
        return(
            <FullScreenHorizontalScroll images={images}/>
        )

}

export default DadaEditorial;


import React from "react";

class FashionProjectDisplay6 extends React.Component {

    render() {
        return (
                <div className="responsive-div" style={{flexDirection:"column", paddingTop:"2%"}}>
                    <div className="row" style={{ paddingBottom:"1%", paddingLeft:"1%", paddingRight:"1%"}}>
                        <div className="col-lg-4">
                            <img className="card-img" src={this.props.image1} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                        </div>
                        <div className="col-lg-4">
                            <img className="card-img" src={this.props.image2} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                        </div>
                        <div className="col-lg-4">
                            <img className="card-img" src={this.props.image3} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                        </div>
                    </div>
                    <div className="row" style={{ paddingLeft:"1%", paddingRight:"1%"}}>
                        <div className="col-lg-4">
                            <img className="card-img" src={this.props.image4} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                        </div>
                        <div className="col-lg-4">
                            <img className="card-img" src={this.props.image5} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                        </div>
                        <div className="col-lg-4">
                            <img className="card-img" src={this.props.image6} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                        </div>
                    </div>
                </div>
        )
    }
}

export default FashionProjectDisplay6;

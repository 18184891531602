import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/4-Melting-Silver/meltingSilver1.jpg";
import image2 from "../../images/photography/4-Melting-Silver/meltingSilver2.jpg";
import image3 from "../../images/photography/4-Melting-Silver/meltingSilver3.jpg";
import image4 from "../../images/photography/4-Melting-Silver/meltingSilver4.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function MeltingSilver(props) {

        const images = [image1, image2, image3, image4];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )

}

export default MeltingSilver;


import React from "react";


class FashionProjectDisplay3 extends React.Component {

    render() {
        return (
            <div className="responsive-div" style={{height:"100vh"}}>
                <div className="row" style={{alignItems:"center", paddingBottom:"1%", paddingLeft:"1%", paddingRight:"1%"}}>
                    <div className="col-lg-4">
                        <img className="card-img" src={this.props.image1} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                    </div>
                    <div className="col-lg-4">
                        <img className="card-img" src={this.props.image2} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                    </div>
                    <div className="col-lg-4">
                        <img className="card-img" src={this.props.image3} alt="" style={{paddingTop:"2%", paddingBottom:"2%"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default FashionProjectDisplay3;

import React from 'react';

function HomePage(props) {
    if (window.innerWidth > 450) {
        return (
            <div className="video-container">
                <video className="video" id="background-video" loop muted autoPlay>
                    <source src={require('../images/home/background-video.mp4')} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    } else {
        return (
            <div className="bgSizeCover" style={{height: "100%"}}>
            </div>
        )
    }

}


export default HomePage





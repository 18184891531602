import './App.css';
import React from "react";
import {
    HashRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Grid, Panel} from 'react-bootstrap'
import Image from 'react-bootstrap/Image';

import ScrollTop from './components/ScrollTop'
import HomePage from './pages/HomePage'
import FashionProjects from "./pages/FashionProjects";
import Photography from "./pages/Photography";
import Jewelry from "./pages/Jewelry";
import About from "./pages/about";
import Illustrations from "./pages/Illustrations";
import FashionProject1 from "./pages/FashionProjectsPages/FashionProject1";
import FashionProject2 from "./pages/FashionProjectsPages/FashionProject2";
import FashionProject3 from "./pages/FashionProjectsPages/FashionProject3";
import FashionProject4 from "./pages/FashionProjectsPages/FashionProject4";
import FashionProject5 from "./pages/FashionProjectsPages/FashionProject5";
import FashionProject6 from "./pages/FashionProjectsPages/FashionProject6";
import FashionProject7 from "./pages/FashionProjectsPages/FashionProject7";
import FashionProject8 from "./pages/FashionProjectsPages/FashionProject8";
import FashionProject9 from "./pages/FashionProjectsPages/FashionProject9";
import FashionProject10 from "./pages/FashionProjectsPages/FashionProject10";
import FashionProject11 from "./pages/FashionProjectsPages/FashionProject11";
import FashionProject12 from "./pages/FashionProjectsPages/FashionProject12";
import FashionProject13 from "./pages/FashionProjectsPages/FashionProject13";
import FashionProject14 from "./pages/FashionProjectsPages/FashionProject14";

import DadaEditorial from "./pages/PhotographyPages/DadaEditorial";
import FashionStory from "./pages/PhotographyPages/FashionStory";
import MeltingSilver from "./pages/PhotographyPages/MeltingSilver";
import GreyScale from "./pages/PhotographyPages/GreyScale";
import Ghost from "./pages/PhotographyPages/Ghost";
import Landscapes from "./pages/PhotographyPages/Landscapes";
import ChinaOnPhotography from "./pages/PhotographyPages/ChinaOnPhotography";
import Reflections from "./pages/PhotographyPages/Reflections";
import Morocco from "./pages/PhotographyPages/Morocco";
import People from "./pages/PhotographyPages/People";
import Santander from "./pages/PhotographyPages/Santander";
import Daily from "./pages/PhotographyPages/Daily";
import CloseUps from "./pages/PhotographyPages/CloseUps";
import NYC from "./pages/PhotographyPages/NYC";
import DeepStories from "./pages/PhotographyPages/DeepStories";
import Paint from "./pages/PhotographyPages/Paint";
import CsdmmRunway from "./pages/PhotographyPages/CsdmmRunway";



class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: 'Nuria Revuelta',
            description: "Fashion Designer and Photographer",
            headerLinks: [
                {title: 'Home', path: '/'},
                {title: 'Fashion Projects', path: '/fashion-projects'},
                {title: 'Jewelry', path: '/jewelry'},
                {title: 'Photography', path: '/photography'},
                {title: 'Illustratrions', path: '/illustrations'},
                {title: 'About', path: '/about'},

            ],
            width: window.innerWidth,

            // BASIC PAGES
            home: {
                title: 'Home title',
                text: 'Home text'
            },
            fashionProjects: {
                title: 'Fashion projects title',
            },
            photography: {
                title: 'Photography title',
            },
            jewelry: {
                title: 'Jewelry title',
            },
            illustrations: {
                title: 'Illustrations title'
            },
            about: {
                title: 'About title',
            }
        }
    }

    render() {
        return (
            <Router>
                    <ScrollTop>
                        <Container className="p-0" bg="transparent" fluid={true} style={{flex: 1}}/>

                        <Navbar className="" bg="transparent" expand="lg" style={{width: "100%"}}>


                            <Navbar.Brand className="nav-brand" align="left" bg="transparent">
                                <Link className="nav-link" to="/">
                                    <Image className="logo" src={require('./images/home/logo.png')}/>
                                </Link>
                            </Navbar.Brand>


                            <Navbar.Toggle aria-controls="basic-navbar-nav" align="right" style={{width: "15%"}}/>

                            <Navbar.Collapse id="basic-navbar-nav" style={{width: "70%"}}>
                                <Nav className="ml-auto" style={{width: "100%"}} data-toggle="collapse"
                                     data-target=".navbar-collapse.show">
                                    <Link className="nav-link-custom" to="/fashion-projects"
                                          style={{color: 'black', paddingTop: "1%", paddingBottom: "1%"}}><b>FASHION
                                        PROJECTS</b></Link>
                                    <Link className="nav-link-custom" to="/jewelry"
                                          style={{color: 'black', paddingTop: "1%", paddingBottom: "1%"}}><b>JEWELRY</b></Link>
                                    <Link className="nav-link-custom" to="/photography"
                                          style={{
                                              color: 'black',
                                              paddingTop: "1%",
                                              paddingBottom: "1%"
                                          }}><b>PHOTOGRAPHY</b></Link>
                                    <Link className="nav-link-custom" to="/illustrations"
                                          style={{
                                              color: 'black',
                                              paddingTop: "1%",
                                              paddingBottom: "1%"
                                          }}><b>ILLUSTRATIONS</b></Link>
                                    <Link className="nav-link-custom" to="/about" style={{
                                        color: 'black',
                                        paddingTop: "1%",
                                        paddingBottom: "1%"
                                    }}><b>ABOUT</b></Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>

                        <Switch>
                            /*Main links*/
                            <Route exact path="/"
                                   render={() => <HomePage title={this.state.home.title}
                                                           text={this.state.home.text}/>}/>
                            <Route exact path="/fashion-projects"
                                   render={() => <FashionProjects title={this.state.fashionProjects.title}/>}/>
                            <Route exact path="/photography"
                                   render={() => <Photography title={this.state.photography.title}/>}/>
                            <Route exact path="/jewelry" render={() => <Jewelry title={this.state.jewelry.title}/>}/>
                            <Route exact path="/illustrations"
                                   render={() => <Illustrations title={this.state.illustrations.title}/>}/>
                            <Route exact path="/about" render={() => <About title={this.state.about.title}/>}/>

                            /*Fashion Projects links*/
                            <Route exact path="/fashion-projects/fashion-project-1" component={FashionProject1}/>
                            <Route exact path="/fashion-projects/fashion-project-2" component={FashionProject2}/>
                            <Route exact path="/fashion-projects/fashion-project-3" component={FashionProject3}/>
                            <Route exact path="/fashion-projects/fashion-project-4" component={FashionProject4}/>
                            <Route exact path="/fashion-projects/fashion-project-5" component={FashionProject5}/>
                            <Route exact path="/fashion-projects/fashion-project-6" component={FashionProject6}/>
                            <Route exact path="/fashion-projects/fashion-project-7" component={FashionProject7}/>
                            <Route exact path="/fashion-projects/fashion-project-8" component={FashionProject8}/>
                            <Route exact path="/fashion-projects/fashion-project-9" component={FashionProject9}/>
                            <Route exact path="/fashion-projects/fashion-project-10" component={FashionProject10}/>
                            <Route exact path="/fashion-projects/fashion-project-11" component={FashionProject11}/>
                            <Route exact path="/fashion-projects/fashion-project-12" component={FashionProject12}/>
                            <Route exact path="/fashion-projects/fashion-project-13" component={FashionProject13}/>
                            <Route exact path="/fashion-projects/fashion-project-14" component={FashionProject14}/>

                            /*Photography link*/
                            <Route exact path="/photography/dada-editorial" render={() => <DadaEditorial/>}/>
                            <Route exact path="/photography/fashion-story" render={() =><FashionStory/>}/>
                            <Route exact path="/photography/close-ups" render={() =><CloseUps/>}/>
                            <Route exact path="/photography/melting-silver" render={() =><MeltingSilver/>}/>
                            <Route exact path="/photography/grey-scale" render={() =><GreyScale/>}/>
                            <Route exact path="/photography/ghost" render={() =><Ghost/>}/>
                            <Route exact path="/photography/landscapes" render={() =><Landscapes/>}/>
                            <Route exact path="/photography/china-on-photography" render={() =><ChinaOnPhotography/>}/>
                            <Route exact path="/photography/reflections" render={() =><Reflections/>}/>
                            <Route exact path="/photography/morocco" render={() =><Morocco/>}/>
                            <Route exact path="/photography/people" render={() =><People/>}/>
                            <Route exact path="/photography/santander" render={() =><Santander/>}/>
                            <Route exact path="/photography/daily" render={() =><Daily/>}/>
                            <Route exact path="/photography/nyc" render={() =><NYC/>}/>
                            <Route exact path="/photography/deep-stories" render={() =><DeepStories/>}/>
                            <Route exact path="/photography/paint" render={() =><Paint/>}/>
                            <Route exact path="/photography/csdmm-runway" render={() =><CsdmmRunway/>}/>


                        </Switch>
                    </ScrollTop>
            </Router>
        );
    }
}


export default App;


import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/7-Landscapes/landscapes1.jpg";
import image2 from "../../images/photography/7-Landscapes/landscapes2.jpg";
import image3 from "../../images/photography/7-Landscapes/landscapes3.jpg";
import image4 from "../../images/photography/7-Landscapes/landscapes4.jpg";
import image5 from "../../images/photography/7-Landscapes/landscapes5.jpg";
import image6 from "../../images/photography/7-Landscapes/landscapes6.jpg";
import image7 from "../../images/photography/7-Landscapes/landscapes7.jpg";
import image8 from "../../images/photography/7-Landscapes/landscapes8.jpg";
import image9 from "../../images/photography/7-Landscapes/landscapes9.jpg";
import image10 from "../../images/photography/7-Landscapes/landscapes10.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";



function Landscapes(props) {

        const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )

}

export default Landscapes;


import React from 'react';

import i1 from '../images/illustrations/i1.jpg';
import i2 from '../images/illustrations/i2.jpg';
import i3 from '../images/illustrations/i3.jpg';
import i4 from '../images/illustrations/i4.jpg';
import i5 from '../images/illustrations/i5.jpg';
import i6 from '../images/illustrations/i6.jpg';
import i7 from '../images/illustrations/i7.jpg';
import i8 from '../images/illustrations/i8.jpg';
import i9 from '../images/illustrations/i9.jpg';
import i10 from '../images/illustrations/i10.jpg';
import FullScreenHorizontalScroll from "../components/FullScreenHorizontalScroll";


function Illustrations(props) {

    const images = [i1, i2, i3, i4, i5, i6, i7, i8, i9, i10];

    return (
    <FullScreenHorizontalScroll images={images}/>
    )
}


export default Illustrations;





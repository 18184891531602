import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/12-Santander/santander1.jpg";
import image2 from "../../images/photography/12-Santander/santander2.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function Santander(props) {

        const images = [image1, image2];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )
}

export default Santander;


import React from 'react';
import HoverImage from "react-hover-image";

import fp1 from '../images/fashion_projects/fp1.jpg';
import fp1i2 from '../images/fashion_projects/fp1i2.jpg';

import fp2 from '../images/fashion_projects/fp2.jpg';
import fp2i2 from '../images/fashion_projects/fp2i2.jpg';

import fp3 from '../images/fashion_projects/fp3.jpg';
import fp3i2 from '../images/fashion_projects/fp3i2.jpg';

import fp4 from '../images/fashion_projects/fp4.jpg';
import fp4i2 from '../images/fashion_projects/fp4i2.jpg';

import fp5 from '../images/fashion_projects/fp5.jpg';
import fp5i2 from '../images/fashion_projects/fp5i2.jpg';

import fp6 from '../images/fashion_projects/fp6.jpg';
import fp6i2 from '../images/fashion_projects/fp6i2.jpg';

import fp7 from '../images/fashion_projects/fp7.jpg';
import fp7i2 from '../images/fashion_projects/fp7i2.jpg';

import fp8 from '../images/fashion_projects/fp8.jpg';
import fp8i2 from '../images/fashion_projects/fp8i2.jpg';

import fp9 from '../images/fashion_projects/fp9.jpg';
import fp9i2 from '../images/fashion_projects/fp9i2.jpg';

import fp10 from '../images/fashion_projects/fp10.jpg';
import fp10i2 from '../images/fashion_projects/fp10i2.jpg';

import fp11 from '../images/fashion_projects/fp11.jpg';
import fp11i2 from '../images/fashion_projects/fp11i2.jpg';

import fp12 from '../images/fashion_projects/fp12.jpg';
import fp12i2 from '../images/fashion_projects/fp12i2.jpg';

import fp13 from '../images/fashion_projects/fp13.jpg';
import fp13i2 from '../images/fashion_projects/fp13i2.jpg';

import fp14 from '../images/fashion_projects/fp14.jpg';
import fp14i2 from '../images/fashion_projects/fp14i2.jpg';


import {
    Link
} from "react-router-dom";

function FashionProjects(props) {

        return (
            <div className="">
                <div className="row" align="center" style={{paddingLeft: "1%", paddingRight: "1%"}}>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-1">
                            <HoverImage className="card-img hover-luis" src={fp1} hoverSrc={fp1i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-2">
                            <HoverImage className="card-img hover-luis" src={fp2} hoverSrc={fp2i2} />
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-3">
                            <HoverImage className="card-img hover-luis" src={fp3} hoverSrc={fp3i2}/>
                        </Link>
                    </div>
                </div>
                <div className="row" align="center" style={{paddingLeft: "1%", paddingRight: "1%"}}>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-4">
                            <HoverImage className="card-img hover-luis" src={fp4} hoverSrc={fp4i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-5">
                            <HoverImage className="card-img hover-luis" src={fp5} hoverSrc={fp5i2}/>
                        </Link>
                    </div>
                </div>
                <div className="row" align="center" style={{paddingLeft: "1%", paddingRight: "1%"}}>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-6">
                            <HoverImage className="card-img hover-luis" src={fp6} hoverSrc={fp6i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-7">
                            <HoverImage className="card-img hover-luis" src={fp7} hoverSrc={fp7i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-8">
                            <HoverImage className="card-img hover-luis" src={fp8} hoverSrc={fp8i2}/>
                        </Link>
                    </div>
                </div>
                <div className="row" align="center" style={{paddingLeft: "1%", paddingRight: "1%"}}>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-9">
                            <HoverImage className="card-img hover-luis" src={fp9} hoverSrc={fp9i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-10">
                            <HoverImage className="card-img hover-luis" src={fp10} hoverSrc={fp10i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-11">
                            <HoverImage className="card-img hover-luis" src={fp11} hoverSrc={fp11i2}/>
                        </Link>
                    </div>
                </div>
                <div className="row" align="center" style={{paddingLeft: "1%", paddingRight: "1%"}}>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-12">
                            <HoverImage className="card-img hover-luis" src={fp12} hoverSrc={fp12i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-13">
                            <HoverImage className="card-img hover-luis" src={fp13} hoverSrc={fp13i2}/>
                        </Link>
                    </div>
                    <div className="col-lg-4" style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        <Link to="/fashion-projects/fashion-project-14">
                            <HoverImage className="card-img hover-luis" src={fp14} hoverSrc={fp14i2}/>
                        </Link>
                    </div>
                </div>
            </div>
        );
}


export default FashionProjects;

import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/11-PEOPLE/people1.jpg";
import image2 from "../../images/photography/11-PEOPLE/people2.jpg";
import image3 from "../../images/photography/11-PEOPLE/people3.jpg";
import image4 from "../../images/photography/11-PEOPLE/people4.jpg";
import image5 from "../../images/photography/11-PEOPLE/people5.jpg";
import image6 from "../../images/photography/11-PEOPLE/people6.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function People(props) {

        const images = [image1, image2, image3, image4, image5, image6];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )

}

export default People;


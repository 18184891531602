import React from 'react';
import {
    Link
} from "react-router-dom";

import image1 from "../../images/photography/3-CLOSE-UPS/closeUps1.jpg";
import image2 from "../../images/photography/3-CLOSE-UPS/closeUps2.jpg";
import image3 from "../../images/photography/3-CLOSE-UPS/closeUps3.jpg";
import image4 from "../../images/photography/3-CLOSE-UPS/closeUps4.jpg";
import FullScreenHorizontalScroll from "../../components/FullScreenHorizontalScroll";


function CloseUps(props) {

        const images = [image1, image2, image3, image4];
        return (
            <FullScreenHorizontalScroll images={images}/>
        )
}

export default CloseUps;

